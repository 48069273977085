import { Image, Nav, Navbar } from "react-bootstrap";

function Header() {
  const LOGO = "https://www.codere.es/_catalogs/masterpage/codere/img/logo.svg";

  return (
    <Navbar className="header_nav">
      <Nav.Link
        href="https://m.mdz.codere.bet.ar/deportesMendoza/"
        rel="nofollow"
      >
        <Image src={LOGO} alt="Logo" />
      </Nav.Link>
    </Navbar>
  );
}

export default Header;
