import Header from "./Components/Header";
import Content from "./Components/Content";
import Footer from "./Components/Footer";

export const prefix = "https://www.codere.bet.ar/assets/mendosa";
function App() {
  return (
    <div id="app">
      <Header />
      <div className="body">
        <Content />
        <Footer />
      </div>
    </div>
  );
}

export default App;
