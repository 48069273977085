import React from "react";
import { Image, Nav } from "react-bootstrap";
import { prefix } from "../App";
import { isMobile } from "react-device-detect";

export default function Footer() {
  const png = "png";

  const logos = [
    "https://api.whatsapp.com/send?phone=5492614722471",
    "",
    "http://138.99.7.48/Produccion/preautoexclusion_formulario.aspx",
    "https://www.mendoza.gov.ar/juegosycasinos/",
    "",
  ];

  const openWindow = (url, width, height) => {
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      url,
      "_blank",
      `width=${width}, height=${height}, left=${left}px, top=${top}`
    );
  };

  return (
    <footer>
      <div className="flexed centered logos">
        {logos.map((link, k) => (
          <Nav.Link
            key={k}
            href={k != 2 || isMobile ? link : ""}
            rel="nofollow"
            target="_blank"
            onClick={() => {
              if (k === 2 && !isMobile) openWindow(link, 800, 600);
              return false;
            }}
          >
            <Image
              className={`posicion_${k + 1}`}
              alt={`posicion ${k + 1}`}
              src={`${prefix}/posicion_${k + 1}.${png}`}
            />
          </Nav.Link>
        ))}
      </div>
      <div className="legals mt-4" style={{ marginBottom: "2vw" }}>
        El sitio web es operado por CODERE ONLINE ARGENTINA S.A. – U.T. y CODERE
        ONLINE ARGENTINA S.A. CUIT N°30-71772632-0, bajo licencia otorgada por
        el Instituto Provincial de Juegos y Casinos de la Provincia de Mendoza.
        El ámbito de aplicación se circunscribe a la Provincia de Mendoza. Los
        datos son proporcionados a CODERE ONLINE ARGENTINA S.A. CUIT
        N°30-71772632-0. El titular de los datos personales tiene la facultad de
        ejercer el derecho de acceso a sus datos en forma gratuita a intervalos
        no inferiores a seis meses, salvo que se acredite un interés legítimo al
        efecto (artículo 14, inciso 3 de la Ley N 25.326, la “Ley”). La AGENCIA
        DE ACCESO A LA INFORMACIÓN PÚBLICA, órgano de aplicación de la Ley,
        atiende las denuncias y reclamos de los usuarios por eventuales
        incumplimientos de la normativa vigente en materia de protección de
        datos personales. Todos los derechos reservados.
      </div>
    </footer>
  );
}
