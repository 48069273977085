import {
  Col,
  Container,
  Form,
  Row,
  InputGroup,
  Button,
  Modal,
  Image,
  FloatingLabel,
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import React, { useState } from "react";
import axios from "axios";
import Floating from "./Floating";
import { prefix } from "../App";
import { dni_list, binarySearch } from "../JSON/data";

export default function Content() {
  const digitOnlyRegex = /^\d+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?=(?:\D*\d){10}\D*$)[\d\s-]+$/;
  const digitRegex = /^[0-9 -]*$/;
  const phoneCode = "+54";

  const err_msg = "Por favor complete este campo";

  const [showModal, setShowModal] = useState(false);

  const [dni, setDNI] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("Mendoza");

  const [dniError, setDniError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [regionError, setRegionError] = useState(false);

  const [modalTitle, setModalTitle] = useState(""); // Tus datos fueron registrados correctamente
  const [modalText, setModalText] = useState(""); // ¡MUCHAS GRACIAS!​

  const validators = ["dni", "name", "phone", "mail", "address", "region"];

  const validate = (obj, validators) => {
    const failedValidators = [];

    validators.forEach((key) => {
      const value = key.split(".").reduce((acc, cur) => acc?.[cur], obj);

      if ([undefined, null, ""].includes(value)) {
        // console.log(key);
        failedValidators.push(key);
      }
    });
    // console.log(failedValidators);
    return failedValidators;
  };

  const confirmData = () => {
    const userInfo = {
      dni: dni,
      name: name,
      phone: `${phoneCode} ${phone}`,
      mail: mail,
      address: address,
      region: region,
    };

    console.log(userInfo);

    let faildFields = validate(userInfo, validators);

    // console.log(faildFields);

    if (faildFields.length) {
      faildFields.forEach((fail) => {
        switch (fail) {
          case "dni":
            setDniError(true);
            break;
          case "name":
            setNameError(true);
            break;
          case "address":
            setAddressError(true);
            break;
          case "region":
            setRegionError(true);
            break;
          case "phone":
            setPhoneError(true);
            break;
          case "mail":
            setEmailError(true);
            break;
        }
      });
      return;
    }

    if (!phoneRegex.test(phone)) {
      // console.log("phone error");
      setPhoneError(true);
      return;
    } else setPhoneError(false);

    if (!emailRegex.test(mail)) {
      setEmailError(true);
      console.log("email error");
      return;
    } else {
      setEmailError(false);
    }

    // DO IT WITH FRONT SIDE

    // let found = binarySearch(dni_list, dni);
    // if (found) {
    //   setModalTitle("ERROR DE INICIO DE SESIÓN");
    //   setModalText(
    //     "Actualmente tu cuenta tiene el acceso restringido por autoexclusión.Recuerda que si quieres retirar tu saldo podrás hacerlo contactando con nuestro equipo de atención al cliente.(error710)!"
    //   );
    // } else {
    //   setModalTitle("¡MUCHAS GRACIAS!​");
    //   setModalText("Tus datos fueron registrados correctamente");
    // }

    // setShowModal(true);

    // DO IT WITH SERVER SIDE
    axios
      .post(process.env.REACT_APP_HTTP, userInfo, {
        headers: {
          "Content-Type": process.env.REACT_APP_HTTP_HEADER,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 12000) {
          setModalTitle("ERROR DE INICIO DE SESIÓN");
          setModalText(
            "Actualmente tu cuenta tiene el acceso restringido por autoexclusión.\nCualquier duda o consulta, contactate con nuestro equipo de atención al cliente."
          );
        } else if (res.data.success) {
          setModalTitle("¡MUCHAS GRACIAS!​");
          setModalText("Tus datos fueron registrados correctamente");
        }
        setShowModal(true);

        // else console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="content">
      <Row className="mb-5">
        <Col className="content_form" xl={3}>
          {isMobile ? <Floating /> : null}
          <p className="mb-2" style={{ paddingTop: "0", color: "#fff" }}>
            Ingresá tus datos para próximamente ser parte de Codere
          </p>
          <Form>
            <span className="mb-3 inp-spn">
              <InputGroup>
                <InputGroup.Text>Número de DNI</InputGroup.Text>
                <Form.Control
                  maxLength={8}
                  style={{ textAlign: "left" }}
                  value={dni}
                  onChange={(e) => {
                    if (
                      digitOnlyRegex.test(e.target.value) ||
                      !e.target.value
                    ) {
                      setDNI(e.target.value);
                      setDniError(false);
                    } else setDniError(true);
                    // setNameError(false);
                  }}
                  placeholder="12345678"
                />
              </InputGroup>
              {dniError ? <div className="error">{err_msg}</div> : null}
            </span>
            <span className="mb-3 inp-spn">
              <InputGroup>
                <InputGroup.Text>Nombre y Apellido</InputGroup.Text>
                <Form.Control
                  style={{ textAlign: "left" }}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                  }}
                  placeholder="José Pérez"
                />
              </InputGroup>
              {nameError ? <div className="error">{err_msg}</div> : null}
            </span>

            <span className="mb-3 inp-spn">
              <InputGroup>
                <InputGroup.Text>Dirección</InputGroup.Text>
                <FloatingLabel controlId="floatingInput" label="Calle">
                  <Form.Control
                    style={{ textAlign: "left" }}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setAddressError(false);
                    }}
                    placeholder="Calle"
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingInput" label="Provincia">
                  <Form.Control
                    style={{ textAlign: "left" }}
                    value={region}
                    onChange={(e) => {
                      setRegion(e.target.value);
                      setRegionError(false);
                    }}
                    placeholder="Provincia"
                  />
                </FloatingLabel>
              </InputGroup>
              {addressError || regionError ? (
                <div className="error">{err_msg}</div>
              ) : null}
            </span>

            <span className="mb-3 inp-spn">
              <InputGroup>
                <InputGroup.Text>Teléfono</InputGroup.Text>
                <InputGroup.Text>
                  <Image
                    style={{ gap: "7.5px" }}
                    width={24}
                    src={`${prefix}/arg-flag.png`}
                  />
                  {phoneCode}
                </InputGroup.Text>
                <Form.Control
                  style={{ textAlign: "left" }}
                  value={phone}
                  onChange={(e) => {
                    if (digitRegex.test(e.target.value))
                      setPhone(e.target.value);
                    setPhoneError(false);
                  }}
                  placeholder="261-111-1111"
                />
              </InputGroup>
              {phoneError ? (
                <div className="error">Formato de Teléfono invalido</div>
              ) : null}
            </span>

            <span className="mb-3 inp-spn">
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  style={{ textAlign: "left" }}
                  value={mail}
                  onChange={(e) => {
                    setMail(e.target.value);
                    setEmailError(false);
                  }}
                  placeholder="Jose@gmail.com"
                />
              </InputGroup>
              {emailError ? (
                <div className="error">Formato de email invalido!</div>
              ) : null}
            </span>

            <Button onClick={() => confirmData()}>Enviar</Button>
          </Form>
        </Col>
        <Col xl={8}>
          <div
            className="bg"
            style={{
              backgroundImage: `url(https://www.codere.bet.ar/assets/splash-banners/special-lp-${
                isMobile ? "mobile" : "desktop"
              }.jpg)`,
              backgroundSize: "cover",
            }}
          ></div>
          {!isMobile ? <Floating /> : null}
        </Col>
      </Row>

      <Modal
        className="submit-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalText.split("\n").map((p, i) => (
            <p style={{ padding: "0" }} key={i}>
              {p}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
            href="https://m.mdz.codere.bet.ar/deportesMendoza/"
            rel="nofollow"
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
